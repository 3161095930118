import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Form, FormGroup, Label, Input, Collapse } from "reactstrap";
import { useForm } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { moneyMask } from "../../utils";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { omit } from "lodash";
import { report } from "../../services";
import { BsCheck2Circle } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { BottomSheet } from "react-spring-bottom-sheet";

interface Props {
  isShown: boolean;
  result: any;
  onClose: Function;
  listParams: any;
}
const schema = yup.object({});
function ResultModal(props: Props) {
  const { isShown, result, onClose, listParams } = props;
  const [modal, setModal] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const { handleSubmit, setValue, getValues } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    setModal(isShown);
  }, [isShown]);

  const toggle = () => {
    setModal(!modal);
    onClose();
  };

  const onSubmit = async (data: any) => {
    const reason = getValues("reason");
    const req = omit(
      { ...listParams, reason, price: result.averagePrice },
      "from",
      "size"
    );
    console.log("onSubmit -> req", req);
    report(req)
      .then(() => {
        setIsFinished(true);
        setTimeout(() => {
          setIsFinished(false);
          toggle();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <BottomSheet
      open={modal}
      className="bottom-sheet"
      defaultSnap={({ snapPoints, lastSnap }) =>
        lastSnap ?? Math.min(...snapPoints)
      }
      snapPoints={({ maxHeight }) => [maxHeight * 0.9, maxHeight * 0.9]}
      initialFocusRef={false}
      expandOnContentDrag={true}
      header={<div>Báo cáo kết quả</div>}
      footer={
        <div className="d-flex justify-content-between">
          <Button
            color="secondary"
            outline
            block
            onClick={toggle}
            className="me-2"
          >
            Huỷ
          </Button>
          <Button
            type="submit"
            color="primary"
            outline
            block
            onClick={onSubmit}
            disabled={isFinished}
          >
            Gửi
          </Button>
        </div>
      }
    >
      {isFinished ? (
        <div className="text-center row success" style={{ margin: " auto" }}>
          <BsCheck2Circle size={60} className="text-success" />
          <span>Gửi thành công</span>
        </div>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)} className="m-2">
          <Label className="link-primary" onClick={() => setOpen(!isOpen)}>
            Thông tin chi tiết
            {isOpen ? (
              <IoIosArrowUp className="ms-1" />
            ) : (
              <IoIosArrowDown className="ms-1" />
            )}
          </Label>
          <Collapse isOpen={isOpen}>
            <FormGroup>
              <Label for="exampleEmail">Thương hiệu</Label>
              <Input value={listParams?.brand || ""} disabled />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Dòng sản phẩm</Label>
              <Input value={listParams?.model || ""} disabled />
            </FormGroup>
            {listParams?.version && (
              <FormGroup>
                <Label for="exampleEmail">Dòng sản phẩm</Label>
                <Input value={listParams?.version} disabled />
              </FormGroup>
            )}
            <FormGroup>
              <Label for="exampleEmail">Thông tin chi tiết</Label>
              <CreatableSelect
                isMulti
                defaultValue={listParams?.attributes}
                isDisabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Không bao gồm từ khóa</Label>
              <Input isMulti defaultValue={listParams?.must_not} disabled />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Giá định giá</Label>
              <Input value={moneyMask(result?.averagePrice || 0)} disabled />
            </FormGroup>
          </Collapse>

          <FormGroup>
            <Label for="exampleEmail">Lý do phản hồi</Label>
            <Input
              type="textarea"
              onChange={(e) => setValue("reason", e.target.value)}
            />
          </FormGroup>
        </Form>
      )}
    </BottomSheet>
  );
}

export default ResultModal;
