import React from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "../../types/common";
import { Sim } from "./Sim";
import { Generic } from "./Generic";

export const ResultScreen = () => {
  const filter = useSelector((state: GlobalState) => state?.valuation?.filter);
  const { assetType } = filter;
  const RenderForm = React.memo(() => {
    switch (assetType) {
      case "sim":
        return <Sim />;
      default:
        return <Generic />;
    }
  });

  return <RenderForm />;
};
