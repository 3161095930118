import * as React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import {
  FilterScreen,
  ReferenceDataScreen,
  ResultScreen,
  Guide,
} from "./pages";

function MainRoutes() {
  return (
    <div className="main">
      <Routes>
        <Route path="/filter" element={<FilterScreen />} />
        <Route path="/result" element={<ResultScreen />} />
        <Route path="/referrence" element={<ReferenceDataScreen />} />
        <Route path="/guide" element={<Guide />} />
      </Routes>
    </div>
  );
}

export default MainRoutes;
