import React, { useEffect, useState } from "react";
import { Container, Col, Row, FormGroup, Label } from "reactstrap";
import { useSearchParams } from "react-router-dom";
import { DhoFrom } from "./DhoForm";
import { GenericForm } from "./GenericForm";
import { DropdownPicker } from "../../components/elements";
import { brandEnableForms, deserializeRecord } from "../../utils";
import { BrandItem, GlobalState, ItemRecord } from "../../types/common";
import { setFilter } from "../../redux/valuationSlice";
import { getBrands } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { SimForm } from "./SimForm";
import { CameraForm } from "./CameraForm";

export const FilterScreen = () => {
  let [searchParams] = useSearchParams();
  const assetType = searchParams.get("asset_type");
  const [brands, setBrands] = useState<BrandItem[]>();
  const [brandSelected, setBrandSelected] = useState<ItemRecord>();
  const dispatch = useDispatch();

  const RenderForm = React.memo(() => {
    switch (assetType) {
      case "dho":
        return <DhoFrom defaultBrand={brandSelected} />;
      case "sim":
        return <SimForm />;
      case "cmr":
        return <CameraForm defaultBrand={brandSelected} />;
      default:
        return <GenericForm defaultBrand={brandSelected} />;
    }
  });

  const filter = useSelector((state: GlobalState) => state?.valuation?.filter);

  const initialize = React.useCallback(() => {
    dispatch(
      setFilter({
        assetType,
      })
    );
    if (assetType) getBrands(assetType).then((res) => setBrands(res));
  }, [assetType, dispatch]);

  const _onSelectBrand = async (item: ItemRecord) => {
    if (!assetType) return;
    try {
      setBrandSelected(item);
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    setBrandSelected(filter.brand);
    initialize();
  }, [assetType]);

  return (
    <Container className="my-3">
      <Row>
        <Col>
          {assetType && brandEnableForms.includes(assetType) && (
            <FormGroup>
              <Label for="exampleEmail">Thương hiệu</Label>(
              <small className="text-danger">*</small>)
              <DropdownPicker
                data={deserializeRecord(brands)}
                onSelect={_onSelectBrand}
                placeholder="Vui lòng chọn thương hiệu"
                selected={brandSelected}
                error={!assetType ? "Vui lòng chọn thương hiệu" : ""}
              />
            </FormGroup>
          )}
          <RenderForm />
        </Col>
      </Row>
    </Container>
  );
};
