import axios, { AxiosRequestConfig } from "axios";
import { serviceConfig } from "../config";
import { BrandItem } from "../types/common";
import { get, omit } from "lodash";

export const httpRequest = async (config: AxiosRequestConfig) => {
  try {
    const result = await axios({
      baseURL: serviceConfig.baseUrl,
      ...config,
    });
    return result.data;
  } catch (e) {
    throw e;
  }
};

export const getBrands = async (assetType: string): Promise<BrandItem[]> => {
  try {
    const brands = await httpRequest({
      method: "GET",
      url: `/rd/market-data/v2/asset-types/${assetType}/brands`,
    });
    return brands;
  } catch (e) {
    throw e;
  }
};

export const getModelByBrandId = async (assetType: string, brandId: string) => {
  try {
    const brands = await httpRequest({
      method: "GET",
      url: `/rd/market-data/v2/asset-types/${assetType}/brands/${brandId}/models`,
    });
    return brands;
  } catch (e) {
    throw e;
  }
};

export const getModelBySubtype = async (
  assetType: string,
  brandId: string,
  subType: string
) => {
  try {
    const brands = await httpRequest({
      method: "GET",
      url: `/rd/market-data/v2/asset-types/${assetType}/brands/${brandId}/models?subType=${subType}`,
    });
    return brands;
  } catch (e) {
    throw e;
  }
};

export const getModelDetailById = async (modelId: string) => {
  try {
    const model = await httpRequest({
      method: "GET",
      url: `/rd/market-data/v2/models/${modelId}`,
    });
    return model;
  } catch (e) {
    throw e;
  }
};

export const valuation = async (payload: any) => {
  try {
    const { assetType } = payload;

    const res = await httpRequest({
      method: "POST",
      url: `/digital-svc/assets-appraise/v1/assets/${assetType.toLowerCase()}/appraise`,
      data: omit(payload, "assetType"),
    });
    return res;
  } catch (e) {
    throw e;
  }
};
export const valuationSim = async (phoneNumber?: string) => {
  try {
    const res = await httpRequest({
      method: "GET",
      url: `/rd/data-prediction/v2/predict?number=${phoneNumber}`,
      headers: {},
    });
    const result = {
      similarNumbers: get(res, "similar_numbers", []),
      averagePrice: get(res, "average_price"),
      suggestPrice: get(res, "suggest_price"),
      tags: get(res, "tags", []),
    };
    return result;
  } catch (e) {
    throw e;
  }
};

export const referenceList = async (payload: any) => {
  try {
    const { assetType } = payload;
    const list = await httpRequest({
      method: "POST",
      url: `/digital-svc/assets-appraise/v1/assets/${assetType.toLowerCase()}/list`,
      data: omit(payload, "assetType"),
    });
    return list;
  } catch (e) {
    throw e;
  }
};

export const getAttributeOptionsFilter = async (query: string) => {
  try {
    const options = await httpRequest({
      method: "GET",
      url: `/rd/market-data/v2/attribute-options?${query}`,
    });
    return options;
  } catch (e) {
    throw e;
  }
};

export const report = async (data: any) => {
  try {
    await httpRequest({
      method: "POST",
      url: `/digital-svc/assets-appraise/v1/assets/report`,
      data: data,
    });
  } catch (error) {}
};

export const getValuationGuide = async () => {
  try {
    const resp = await httpRequest({
      baseURL: process.env.REACT_APP_STRAPI_API_URL,
      url: "/api/posts/22",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
      },
    });
    return resp.data;
  } catch (err) {
    throw err;
  }
};
