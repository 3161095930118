import React, { useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  Card,
  Badge,
} from "reactstrap";
import { referenceList } from "../../services";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { GlobalState } from "../../types/common";
import InfiniteScroll from "react-infinite-scroll-component";
import { moneyMask } from "../../utils";

export const ReferenceDataScreen = () => {
  const filter = useSelector((state: GlobalState) => state?.valuation?.filter);
  const { assetType, brand, model, version, includes } = filter;

  const [checkEnd, setCheckEnd] = useState(true);

  const [list, setList] = useState([]);
  const [query, setQuery] = useState({
    size: 20,
    from: 0,
  });

  const request = React.useCallback(() => {
    let listParams = {
      assetType,
      brand: brand?.label,
      model: model?.label,
      attributes: includes?.map((item: any) => item.value),
      size: query?.size,
      from: query.from,
    };
    if (version?.label) {
      listParams = {
        ...listParams,
        attributes: [
          ...includes?.map((item: any) => item.value),
          version?.label,
        ],
      };
    }

    referenceList(listParams)
      .then((res) => {
        if (res.results.length === 0 || res.results.length < 20) {
          setCheckEnd(false);
        }
        setList(res?.results);
      })
      .catch((e) => {
        setCheckEnd(false);
      });
  }, [assetType, model, brand, includes, query]);

  useEffect(() => {
    // request();
    let listParams = {
      assetType,
      brand: brand?.label,
      model: model?.label,
      attributes: includes?.map((item: any) => item.value),
      size: query?.size,
      from: query.from,
    };
    if (version?.label) {
      listParams = {
        ...listParams,
        attributes: [
          ...includes?.map((item: any) => item.value),
          version?.label,
        ],
      };
    }

    referenceList(listParams)
      .then((res) => {
        if (res.results.length === 0 || res.results.length < 20) {
          setCheckEnd(false);
        }
        setList(res?.results);
      })
      .catch((e) => {
        setCheckEnd(false);
      });
  }, [assetType, model, brand, query, includes]);

  const fetchMore = () => {
    const queryParam = {
      size: query?.size,
      from: query?.from + query?.size,
    };
    const listParams = {
      assetType,
      brand: brand?.label,
      model: model?.label,
      attributes: includes,
      ...queryParam,
    };

    referenceList(listParams).then((res) => {
      if (res.results.length === 0 || res.results.length < 20) {
        setCheckEnd(false);
      } else {
        const data = list.concat(get(res, "results"));
        setList(data);
        setQuery(queryParam);
      }
    });
  };

  return (
    <Container className="my-4">
      <Row>
        <Col>
          <Card className="text-center">
            <CardBody>
              <CardTitle tag="h6">Tên tài sản</CardTitle>
              <h4 className="mb-2">
                {brand?.label} - {model?.label}
              </h4>
              {version?.label && (
                <Badge color="info" pill className="mx-1">
                  {version?.label}
                </Badge>
              )}
              {includes?.map((item: any) => (
                <Badge color="info" pill className="mx-1">
                  {item?.label}
                </Badge>
              ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="my-2">Kết quả định giá</h3>
          <InfiniteScroll
            dataLength={list.length} //This is important field to render the next data
            next={fetchMore}
            hasMore={checkEnd}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            // below props only if you need pull down functionality
            refreshFunction={request}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={
              <h3 style={{ textAlign: "center" }}>
                &#8595; Pull down to refresh
              </h3>
            }
            releaseToRefreshContent={
              <h3 style={{ textAlign: "center" }}>
                &#8593; Release to refresh
              </h3>
            }
          >
            {list?.map((item, index) => {
              return (
                <Card className="mb-2" key={index}>
                  <CardBody>
                    <Row>
                      <CardTitle tag="h6">
                        {get(item, "_source.subject")}
                      </CardTitle>
                      <h4 className="mb-2">
                        {moneyMask(get(item, "_source.price"))}
                      </h4>
                    </Row>
                    <CardText
                      className="text-muted text-small text-truncate"
                      tag="small"
                    >
                      {get(item, "_source.body")}
                    </CardText>
                  </CardBody>
                </Card>
              );
            })}
          </InfiniteScroll>
        </Col>
      </Row>
    </Container>
  );
};
