import { get, omit } from "lodash";
import { getModelDetailById } from "../services";
import { Attributes, BrandItem, ItemRecord, ModelItem } from "../types/common";

export const deserializeRecord = (
  data?: BrandItem[] | ModelItem[]
): ItemRecord[] => {
  if (data)
    return data.map((item) => ({
      label: item?.name,
      value: item?.id,
      metadata: omit(item, ["name", "id"]),
    }));
  return [];
};

export const moneyMask = (number: number) => {
  return number.toLocaleString("vi-VN", {
    style: "currency",
    currency: "VND",
  });
};

export const search = (keyword: string, data: ItemRecord[]) => {
  return data.filter((item) =>
    item.label.toLowerCase().includes(keyword.toLowerCase().trim())
  );
};

export const tagAdapter = (tag: string) => {
  switch (tag) {
    case "unsupport":
      return "Chưa hổ trợ định giá";

    default:
      return tag;
  }
};

export const brandEnableForms = [
  "dtd",
  "mtl",
  "body",
  "cmr",
  "xem",
  "oto",
  "tbl",
  "dho",
];

export const CameraSubType = [
  {
    value: "BODY",
    label: "Body",
    metadata: [],
  },
  {
    value: "LENS",
    label: "Lens",
    metadata: [],
  },
];

export const notes = {
  tbl: "Vd: 64 GB, Đen,...",
  dtd: "Vd: 64 GB, Đen,...",
  mtl: "Vd: 256 GB, 15 inch, 8 GB, intel core i5, (onboard, nvidia, amd),...",
  oto: "Vd: 2021, MT, AT,..",
  xem: "Vd: 2021,...",
  dho: "Vd: RA-AG0026E00C",
};

const keyAdapter = {
  Color: "Màu sắc",
  Size: "Kích thước",
  GPU: "Card đồ hoạ",
  Screen_size: "Màn hình",
  CPU: "Vi xử lý",
  Storage: "Bộ nhớ",
  RAM: "Ram",
  Manufacture_year: "Năm SX",
  Version: "Phiên bản",
};
export const transform = (attributes: Attributes) => {
  return attributes.attributeOptions.map((item) => ({
    ...item,
    label: `${get(keyAdapter, attributes.name.replace(" ", "_"), "")}: ${
      item.label
    }`,
    code: attributes.code,
  }));
};

export const getAttributeOptions = async (modelId: string) => {
  const modelDetail = await getModelDetailById(modelId);
  const attributes = get(modelDetail, "attributes", []);
  let attributeOptions: any = [];

  attributes.forEach((item: Attributes) => {
    if (item.code !== "version") {
      attributeOptions.push(transform(item));
    }
  });

  return [].concat(...attributeOptions);
};

export const transformAttribute = (attributeOptions: any, options: any) => {
  const intersection = attributeOptions.filter((rq: any) =>
    options.some((op: any) => rq.id === op.id)
  );

  return intersection;
};
