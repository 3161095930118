import React, { useCallback, useEffect, useState } from "react";
import { Form, FormGroup, Label, Button } from "reactstrap";
import { DropdownPicker } from "../../components/elements";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getModelBySubtype, getModelDetailById } from "../../services";
import { AttributeItem, ItemRecord, ModelItem } from "../../types/common";
import { CameraSubType, deserializeRecord } from "../../utils";
import { get, omit } from "lodash";
import ReactTagInput from "@pathofdev/react-tag-input";
import { setFilter } from "../../redux/valuationSlice";
import { useDispatch } from "react-redux";

const schema = yup
  .object({
    model: yup.object().shape({
      value: yup.string().required("Vui lòng chọn dòng sản phẩm"),
      label: yup.string().required(),
    }),
    subType: yup.object().shape({
      value: yup.string().required("Vui lòng chọn loại sản phẩm"),
      label: yup.string().required(),
    }),
    version: yup.object().shape({
      value: yup.string(),
      label: yup.string(),
    }),
    includes: yup.array().of(yup.string()).default([]),
    excludes: yup.array().of(yup.string()).default([]),
  })
  .required();

interface GenericFormProps {
  defaultBrand?: ItemRecord;
}

export const CameraForm: React.FC<GenericFormProps> = (props) => {
  let [searchParams] = useSearchParams();
  const { defaultBrand } = props;
  const assetType = searchParams.get("asset_type") || "";
  const [models, setModels] = useState<ModelItem[]>();
  const [versions, setVersions] = useState<AttributeItem[]>([]);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (data: any) => {
    dispatch(
      setFilter({
        ...data,
        assetType,
      })
    );
    navigate("/result");
  };

  const getModelsBySubtypeModel = useCallback(
    async (assetType: string, brandId: string, subType: string) => {
      const models = await getModelBySubtype(assetType, brandId, subType);
      if (models) {
        setModels(models);
      }
    },
    [getValues("brand"), getValues("subtype")]
  );

  useEffect(() => {
    if (defaultBrand?.value) {
      setValue("brand", defaultBrand);
    }
  }, [assetType, dispatch, defaultBrand, setValue]);

  const _onSelectSubType = async (item: ItemRecord) => {
    setValue("model", undefined);
    setValue("subType", item);
    defaultBrand &&
      getModelsBySubtypeModel(assetType, defaultBrand.value, item.value);
  };

  const _onSelectModel = async (item: ItemRecord) => {
    setValue("model", item, { shouldValidate: true, shouldDirty: true });
    setValue("version", undefined);
    try {
      const modelDetail = await getModelDetailById(item?.value);
      const attributes = get(modelDetail, "attributes", []);
      const attributeVersion = attributes.find(
        (item: any) => item.code === "version"
      );
      const versions = get(attributeVersion, "attributeOptions", []);
      setVersions(versions);
    } catch (e) {
      throw e;
    }
  };

  const _onSelectVersion = (item: ItemRecord) => {
    setValue("version", omit(item, ["attributeId", "id"]), {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="my-4">
      <FormGroup>
        <Label for="exampleEmail">Loại sản phẩm</Label>(
        <small className="text-danger">*</small>)
        <DropdownPicker
          data={CameraSubType}
          onSelect={_onSelectSubType}
          placeholder="Vui lòng chọn loại sản phẩm"
          selected={getValues("subType")}
          error={get(errors, "subType.value.message")}
        />
      </FormGroup>
      <FormGroup>
        <Label for="exampleEmail">Dòng sản phẩm</Label>(
        <small className="text-danger">*</small>)
        <DropdownPicker
          data={deserializeRecord(models)}
          onSelect={_onSelectModel}
          placeholder="Vui lòng chọn thương hiệu"
          selected={getValues("model")}
          error={get(errors, "model.value.message")}
        />
      </FormGroup>
      {versions.length > 0 && (
        <FormGroup>
          <Label for="exampleEmail">Dòng sản phẩm</Label>
          <DropdownPicker
            data={versions}
            onSelect={_onSelectVersion}
            placeholder="Vui lòng chọn thương hiệu"
            selected={getValues("version")}
            error={get(errors, "version.value.message")}
          />
        </FormGroup>
      )}

      <FormGroup>
        <Label for="exampleEmail">Thông tin chi tiết</Label>
        <Controller
          name="includes"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <ReactTagInput
              {...field}
              tags={field?.value || []}
              placeholder="Phân biệt bằng phím enter"
            />
          )}
        />
        <small className="text-secondary">
          Vd: 75D, 50D,...(có thể bỏ trống)
        </small>
      </FormGroup>
      <FormGroup>
        <Label for="exampleEmail">Không bao gồm từ khóa</Label>
        <Controller
          name="excludes"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <ReactTagInput
              {...field}
              tags={field?.value || []}
              placeholder="Phân biệt bằng phím enter"
            />
          )}
        />
        <small className="text-secondary">
          Vd: bể màn hình, bán xác, trả góp,...(có thể bỏ trống)
        </small>
      </FormGroup>
      <div className="fixed-bottom">
        <div className="m-2">
          <Button block color="danger" disabled={!isDirty || !isValid}>
            Định giá
          </Button>
        </div>
      </div>
    </Form>
  );
};
