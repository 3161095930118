import { Form, FormGroup, Label, Button, Input } from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ItemRecord, InputTarget, GlobalState } from "../../types/common";
import { get } from "lodash";
import { setFilter } from "../../redux/valuationSlice";
import { useDispatch, useSelector } from "react-redux";
import { GenericForm } from "./GenericForm";
import { notes } from "../../utils";
import { useEffect } from "react";

const schema = yup
  .object({
    referenceNumber: yup.string().required("Vui lòng nhập mã tham chiếu"),
    includes: yup.array().of(yup.string()).default([]),
    excludes: yup.array().of(yup.string()).default([]),
  })
  .required();

interface GenericFormProps {
  defaultBrand?: ItemRecord;
}

export const DhoFrom: React.FC<GenericFormProps> = (props) => {
  const { defaultBrand } = props;
  let [searchParams] = useSearchParams();
  const assetType = searchParams.get("asset_type");

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const filter = useSelector((state: GlobalState) => state?.valuation?.filter);

  useEffect(() => {
    if (defaultBrand?.value) {
      setValue("brand", defaultBrand);
    }
    if (filter?.referenceNumber) {
      setValue("referenceNumber", filter?.referenceNumber, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }

    if (filter?.brand) {
      if (defaultBrand?.value !== filter?.brand.value) {
        setValue("referenceNumber", undefined);
      }
    }
  }, [assetType, defaultBrand, setValue, filter]);

  const onSubmit = (data: any) => {
    data.brand = defaultBrand;
    dispatch(
      setFilter({
        ...data,
        assetType,
      })
    );
    navigate("/result");
  };

  const handleChange = (e: InputTarget) => {
    const value = e.target.value;
    setValue("referenceNumber", value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  if (defaultBrand?.label === "Apple Watch") {
    return <GenericForm defaultBrand={defaultBrand} />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="my-4">
      <FormGroup>
        <Label for="exampleEmail">Nhập mã tham chiếu</Label>(
        <small className="text-danger">*</small>)
        <Input
          type="text"
          name="referentCode"
          id="referentCode"
          placeholder="Nhập mã tham chiếu"
          onChange={handleChange}
          defaultValue={getValues("referenceNumber")}
          error={get(errors, "referenceNumber.value.message")}
        />
        <small className="text-secondary">{get(notes, "dho")}</small>
      </FormGroup>
      <div>
        <a
          className="text-danger fw-bold"
          href="https://hotro.vietmoney.vn/mobile/chi-tiet/huong-dan-dinh-gia-dong-ho"
          target="_blank"
          rel="noreferrer"
        >
          Tìm hiểu mã tham chiếu là gì?
        </a>
      </div>
      <div className="fixed-bottom">
        <div className="m-2">
          <Button block color="danger" disabled={!isDirty || !isValid}>
            Định giá
          </Button>
        </div>
      </div>
    </Form>
  );
};
