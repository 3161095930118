import React, { useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  Card,
  Badge,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { valuation } from "../../services";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { GlobalState } from "../../types/common";
import { moneyMask } from "../../utils";
import assetTypeConditions from "../../asset-condition-definition.json";
import { Loading } from "../../components/elements";
import ResultModal from "./ResultModal";
import { IoMdAlert } from "react-icons/io";
import moment from "moment";
export const Generic = () => {
  const filter = useSelector((state: GlobalState) => state?.valuation?.filter);
  const {
    assetType,
    brand,
    model,
    version,
    includes,
    referenceNumber,
    excludes,
  } = filter;

  const [result, setResult] = useState(get(assetTypeConditions, assetType));
  const [isLoading, setLoading] = useState(false);
  const [query, setQuery] = useState({});
  const [modal, setModal] = React.useState(false);

  const request = React.useCallback(() => {
    let listParams = {
      assetType,
      brand: brand?.label === "Apple Watch" ? "Apple" : brand?.label,
      model: model?.label,
      attributes: includes?.map((item: any) => item.value),
      size: 20,
      from: 0,
      referenceNumber,
      must_not: excludes,
    };

    if (version?.label) {
      listParams = {
        ...listParams,
        attributes: [
          ...includes?.map((item: any) => item.value),
          version?.label,
        ],
      };
    }

    setLoading(true);
    setResult(null);
    valuation(listParams)
      .then((res) => {
        const price = get(res, "suggested_price");
        const averagePrice = get(res, "average_price");
        let result = get(assetTypeConditions, assetType);
        const { suggestedPrice } = result;
        result.averagePrice = averagePrice;
        result.suggestedPrice = suggestedPrice.map((item: any) => {
          return {
            ...item,
            price: get(price, item.key),
          };
        });
        setLoading(false);
        setQuery({
          ...listParams,
          attributes: includes,
          version: version?.label,
        });
        setResult(result);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [assetType, model, brand, includes, version, referenceNumber]);

  useEffect(() => {
    request();
  }, [assetType, model, brand, request]);

  const handleSubmit = () => {
    setModal(true);
  };

  return (
    <>
      <Container className="my-3">
        <Row>
          <Col>
            <Card className="text-center">
              <CardBody>
                <CardTitle tag="h6">Tên tài sản</CardTitle>
                {assetType === "dho" && brand.label !== "Apple Watch" ? (
                  <>
                    <h4 className="mb-2">
                      {brand?.label} - {referenceNumber}
                    </h4>
                  </>
                ) : (
                  <>
                    <h4 className="mb-2">
                      {brand?.label} - {model?.label}
                    </h4>
                  </>
                )}
                {version?.label && (
                  <Badge color="info" pill className="mx-1">
                    {version?.label}
                  </Badge>
                )}
                {includes?.map((item: any) => (
                  <Badge color="info" pill className="mx-1">
                    {item.label}
                  </Badge>
                ))}

                <h6 className="mt-3">Giá thị trường</h6>
                <div>
                  <h4>{moneyMask(result?.averagePrice || 0)}</h4>
                </div>

                <div className="text-secondary">
                  <div>Thời gian định giá</div>
                  <div>{moment().format("MM-DD-YYYY HH:MM:SS")}</div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="my-2 text-center">Giá trị định giá</h3>
            {isLoading && <Loading />}
            {result?.suggestedPrice.length > 0 &&
              result?.suggestedPrice.map((item: any) => {
                return (
                  <Card className="mb-2">
                    <CardBody>
                      <Row>
                        <Col>
                          <CardTitle tag="h6">{item.label}</CardTitle>
                        </Col>
                        <Col>
                          <h4 className="mb-2 text-center">
                            {moneyMask(item.price || 0)}
                          </h4>
                        </Col>
                      </Row>
                      <CardText className="text-muted text-small" tag="small">
                        {item.description}
                      </CardText>
                    </CardBody>
                  </Card>
                );
              })}
          </Col>
          <div className="d-flex justify-content-between">
            {result?.averagePrice > 0 && (
              <div style={{ margin: "auto", marginLeft: "0" }}>
                <Link to={"/referrence"} className="text-start">
                  Xem danh sách tham chiếu
                </Link>
              </div>
            )}
            <div className="text-end mt-2">
              <Button size="small" color="light" onClick={handleSubmit}>
                <IoMdAlert className="text-warning me-1" />
                Gửi phản hồi
              </Button>
            </div>
          </div>
        </Row>
      </Container>
      <ResultModal
        listParams={query}
        isShown={modal}
        result={result}
        onClose={() => {
          setModal(false);
        }}
      />
    </>
  );
};
