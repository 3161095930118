import { Form, FormGroup, Label, Button, Input } from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalState, InputTarget } from "../../types/common";
import { get } from "lodash";
import { setFilter } from "../../redux/valuationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const schema = yup
  .object({
    phoneNumber: yup
      .string()
      .required("Nhập số điện thoại")
      .matches(
        /((09|03|07|08|05)+([0-9]{8})\b)/g,
        "Số điện thoại không hợp lệ"
      ),
    includes: yup.array().of(yup.string()).default([]),
    excludes: yup.array().of(yup.string()).default([]),
  })
  .required();

export const SimForm = () => {
  let [searchParams] = useSearchParams();
  const assetType = searchParams.get("asset_type");
  const filter = useSelector((state: GlobalState) => state?.valuation?.filter);
  // console.log("SimForm -> filter", filter);

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setValue("phoneNumber", filter?.phoneNumber, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [filter]);

  const onSubmit = (data: any) => {
    dispatch(
      setFilter({
        ...data,
        assetType,
      })
    );
    navigate("/result");
  };

  const handleChange = (e: InputTarget) => {
    const value = e.target.value;
    setValue("phoneNumber", value, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Label for="exampleEmail">Nhập số điện thoại</Label>
        <span>
          (<small className="text-danger">*</small>)
        </span>
        <Input
          type="text"
          name="phoneNumber"
          id="phoneNumber"
          placeholder="Nhập số điện thoại"
          defaultValue={getValues("phoneNumber")}
          onChange={(e) => handleChange(e)}
          error={get(errors, "phoneNumber.value.message")}
        />
      </FormGroup>
      <div className="fixed-bottom">
        <div className="m-2">
          <Button block color="danger" disabled={!isDirty || !isValid}>
            Định giá
          </Button>
        </div>
      </div>
    </Form>
  );
};
