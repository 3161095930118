import React, { useEffect, useState } from "react";
import { getValuationGuide } from "../../services";

type IGuide = {
  attributes?: {
    content?: string;
  };
};

const Guide = () => {
  const [data, setData] = useState<IGuide>({});

  useEffect(() => {
    getValuationGuide().then((resp) => setData(resp));
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: data?.attributes?.content ?? "" }}
    />
  );
};

export default Guide;
