import { BrowserRouter } from "react-router-dom";
import "./App.css";
import MainRoutes from "./routes";
import { Provider } from "react-redux";
import store from './store'

import "bootstrap/dist/css/bootstrap.min.css";
import "react-spring-bottom-sheet/dist/style.css";
import "@pathofdev/react-tag-input/build/index.css";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <MainRoutes />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
