import React, { useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  CardBody,
  CardTitle,
  Card,
  Badge,
} from "reactstrap";
import { valuationSim } from "../../services";
import { useSelector } from "react-redux";
import { GlobalState, SimPayload } from "../../types/common";
import { moneyMask, tagAdapter } from "../../utils";
import { Loading } from "../../components/elements";
import { get } from "lodash";

export const Sim = () => {
  const filter = useSelector((state: GlobalState) => state?.valuation?.filter);

  const { assetType, phoneNumber } = filter;

  const [result, setResult] = useState<SimPayload | null>();
  const [isLoading, setLoading] = useState(false);

  const request = React.useCallback(() => {
    setResult(null);
    setLoading(true);
    valuationSim(phoneNumber)
      .then((res) => {
        setLoading(false);
        setResult(res);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [phoneNumber]);

  useEffect(() => {
    request();
  }, [assetType, phoneNumber, request]);

  return (
    <Container className="my-4">
      <Row>
        <Col>
          <Card className="text-center">
            <CardBody>
              <CardTitle className="fw-bold" tag="h6">
                Số điện thoại
              </CardTitle>
              <h4 className="mb-2">{phoneNumber}</h4>
              {result?.tags.map((tag) => (
                <Badge color="info" pill className="mx-1">
                  {tag !== "system_unsupport" && tagAdapter(tag)}
                </Badge>
              ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-3 text-center">Kết quả định giá</h3>
          {isLoading && <Loading />}
          {result && Object.keys(result).length > 0 && (
            <>
              <Card className="mb-2">
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle tag="h6">Giá Định giá</CardTitle>
                    </Col>
                    <Col>
                      <h4 className="mb-2 text-center">
                        {moneyMask(result?.averagePrice)}
                      </h4>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <CardTitle tag="h6">Giá cầm</CardTitle>
                    </Col>
                    <Col>
                      <h4 className="mb-2 text-center">
                        {moneyMask(result?.suggestPrice)}
                      </h4>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <h3 className="mt-3 text-center">Danh sách Sim Tương tự</h3>
              {get(result, "similarNumbers", []).map((item: any) => (
                <Card className="mb-2">
                  <CardBody>
                    <Row>
                      <Col>
                        <CardTitle className="fw-bold" tag="h6">
                          {item.mobile_number}
                        </CardTitle>
                      </Col>
                      <Col>
                        <h4 className="mb-2 text-center">
                          {moneyMask(item?.price || 0)}
                        </h4>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ))}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};
