import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Button } from "reactstrap";
import { DropdownPicker } from "../../components/elements";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getAttributeOptionsFilter,
  getModelByBrandId,
  getModelDetailById,
} from "../../services";
import {
  AttributeItem,
  ItemRecord,
  ModelItem,
  GlobalState,
} from "../../types/common";
import {
  deserializeRecord,
  getAttributeOptions,
  notes,
  transformAttribute,
} from "../../utils";
import { get, omit } from "lodash";
import ReactTagInput from "@pathofdev/react-tag-input";
import { setFilter } from "../../redux/valuationSlice";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
const schema = yup
  .object({
    brand: yup.object().shape({
      value: yup.string().required("Vui lòng chọn thương hiệu"),
      label: yup.string().required(),
    }),
    model: yup.object().shape({
      value: yup.string().required("Vui lòng chọn dòng sản phẩm"),
      label: yup.string().required(),
    }),
    version: yup.object().shape({
      value: yup.string(),
      label: yup.string(),
    }),
    excludes: yup.array().of(yup.string()).default([]),
  })
  .required();

interface GenericFormProps {
  defaultBrand?: ItemRecord;
}

export const GenericForm: React.FC<GenericFormProps> = (props) => {
  let [searchParams] = useSearchParams();
  const { defaultBrand } = props;
  const assetType = searchParams.get("asset_type") || "";
  const [models, setModels] = useState<ModelItem[]>();
  const [versions, setVersions] = useState<AttributeItem[]>([]);
  const [attributeOptions, setAttributeOptions] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);
  const [modelAttributes, setModelAttributes] = useState<any>([]);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (data: any) => {
    dispatch(
      setFilter({
        ...data,
        assetType,
      })
    );
    navigate("/result");
  };

  async function getModelsById(assetType: string, brandId: string) {
    const models = await getModelByBrandId(assetType, brandId);
    if (models) {
      setModels(models);
    }
  }

  async function getModelAttributeOptions(modelId: string) {
    const data = await getModelDetailById(modelId);
    if (data) {
      setModelAttributes(get(data, "modelAttributes", []));
    }
  }
  const filter = useSelector((state: GlobalState) => state?.valuation?.filter);

  useEffect(() => {
    if (defaultBrand?.value) {
      setValue("brand", defaultBrand);
      getModelsById(assetType, defaultBrand?.value);
    }

    if (filter?.model) {
      const model = filter.model;
      getModelAttributeOptions(model?.value);
      getAttributeOptions(model?.value).then((res) => {
        setAttributeOptions(res);
      });

      getModelDetailById(model?.value).then((res) => {
        const attributes = get(res, "attributes", []);
        const attributeVersion = attributes.find(
          (item: any) => item.code === "version"
        );
        const versions = get(attributeVersion, "attributeOptions", []);
        setVersions(versions);
      });

      setValue("model", model, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    if (filter.includes) {
      setValue("includes", filter.includes, {
        shouldValidate: true,
        shouldDirty: true,
      });
      let result = attributeOptions.filter(
        (option: any) =>
          !filter.includes.some(
            (q: any) => option.attributeId === q.attributeId
          )
      );

      setOptions(result);
    }

    if (filter?.version) {
      setValue("version", filter.version, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }

    if (filter?.brand) {
      if (defaultBrand?.value !== filter?.brand.value) {
        setValue("model", undefined);
        setValue("version", undefined);
        setValue("includes", undefined);
      }
    }
  }, [assetType, dispatch, setValue, filter]);

  const _onSelectModel = async (item: ItemRecord) => {
    setValue("model", item, { shouldValidate: true, shouldDirty: true });
    setValue("version", undefined);
    setValue("includes", undefined);
    try {
      const modelDetail = await getModelDetailById(item?.value);
      setModelAttributes(get(modelDetail, "modelAttributes", []));
      const attributes = get(modelDetail, "attributes", []);
      const attributeOptions = await getAttributeOptions(item?.value);
      setOptions(
        transformAttribute(attributeOptions, attributes[0].attributeOptions)
      );
      setAttributeOptions(attributeOptions);

      const attributeVersion = attributes.find(
        (item: any) => item.code === "version"
      );
      const versions = get(attributeVersion, "attributeOptions", []);
      if (versions.length > 0) {
        setOptions([]);
        setVersions(versions);
      }
      setValue("includes", []);
    } catch (e) {
      throw e;
    }
  };

  const _onSelectVersion = async (item: any) => {
    setValue("version", omit(item), {
      shouldValidate: true,
    });

    const model = get(getValues("model"), "value");

    const nextAttribute = attributeOptions.find(
      (item: any) => item.code !== "version"
    ).attributeId;

    const queryStr = `attributes[version][]=${item.id}&modelId=${model}&attributeId=${nextAttribute}`;
    const options = await getAttributeOptionsFilter(queryStr);

    const intersection = attributeOptions.filter((rq: any) =>
      options.some((op: any) => rq.id === op.id)
    );

    setValue("includes", []);
    setOptions(intersection);
  };

  const handleChange = async (value: any) => {
    const model = get(getValues("model"), "value");
    const version = get(getValues("version"), "id");

    let queryStr = `modelId=${model}`;

    if (value.length === 0) {
      if (version) {
        const nextAttribute = attributeOptions.find(
          (item: any) => item.code !== "version"
        ).attributeId;

        const queryWithVersion = `attributes[version][]=${version}&modelId=${model}&attributeId=${nextAttribute}`;
        const options = await getAttributeOptionsFilter(queryWithVersion);

        const intersection = attributeOptions.filter((rq: any) =>
          options.some((op: any) => rq.id === op.id)
        );

        setOptions(intersection);
      } else {
        const modelDetail = await getModelDetailById(model);
        const attributes = get(modelDetail, "attributes", []);
        setOptions(
          transformAttribute(attributeOptions, attributes[0].attributeOptions)
        );
      }

      setValue("includes", []);
    } else {
      const data =
        value &&
        value.map((item: any) => ({
          value: item.value,
          label: item.label,
          attributeId: item.attributeId,
          id: item.id,
          code: item.code,
        }));

      setValue("includes", data);
    }

    const selected = getValues("includes");

    if (selected.length > 0) {
      let modelAttributesActive = modelAttributes.filter(
        (attribute: any) => selected[0].attributeId !== attribute.attributeId
      );

      if (version) {
        queryStr += `&attributes[version][]=${version}`;
        modelAttributesActive = modelAttributes.filter(
          (attribute: any) =>
            getValues("version").attributeId !== attribute.attributeId
        );
      }

      if (modelAttributesActive.length >= selected.length) {
        const nextAttribute = attributeOptions.filter(
          (option: any) =>
            !selected.some(
              (value: any) => option.attributeId === value.attributeId
            )
        )[0]?.attributeId;

        for (let i = 0; i < selected.length; i++) {
          const code = selected[i].code;
          const value = selected[i].id;
          queryStr += `&attributes[${code}][]=${value}`;
        }

        queryStr += `&attributeId=${nextAttribute}`;

        const options = await getAttributeOptionsFilter(queryStr);

        const intersection = attributeOptions.filter((rq: any) =>
          options.some((op: any) => rq.id === op.id)
        );

        setOptions(intersection);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="my-4">
      <FormGroup>
        <Label for="exampleEmail">Dòng sản phẩm</Label>
        <span>
          (<small className="text-danger">*</small>)
        </span>
        <DropdownPicker
          data={deserializeRecord(models)}
          onSelect={_onSelectModel}
          placeholder="Vui lòng chọn thương hiệu"
          selected={getValues("model")}
          error={get(errors, "model.value.message")}
        />
      </FormGroup>
      {versions.length > 0 && (
        <FormGroup>
          <Label for="exampleEmail">Dòng sản phẩm</Label>
          <DropdownPicker
            data={versions}
            onSelect={_onSelectVersion}
            placeholder="Vui lòng chọn thương hiệu"
            selected={getValues("version")}
            error={get(errors, "version.value.message")}
          />
        </FormGroup>
      )}

      <FormGroup>
        <Label for="exampleEmail">Thông tin chi tiết</Label>
        <Controller
          name="includes"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CreatableSelect
              {...field}
              isMulti
              options={options}
              onChange={(value) => handleChange(value)}
              defaultValue={getValues("includes")}
            />
          )}
        />
        <small className="text-secondary">{get(notes, assetType)}</small>
      </FormGroup>
      <FormGroup>
        <Label for="exampleEmail">Không bao gồm từ khóa</Label>
        <Controller
          name="excludes"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <ReactTagInput
              {...field}
              tags={field?.value || []}
              placeholder="Phân biệt bằng phím enter"
            />
          )}
        />
        <small className="text-secondary">
          Vd: bể màn hình, bán xác, trả góp,...(có thể bỏ trống)
        </small>
      </FormGroup>
      <div className="fixed-bottom">
        <div className="m-2">
          <Button
            type="submit"
            block
            color="danger"
            disabled={!isDirty || !isValid}
          >
            Định giá
          </Button>
        </div>
      </div>
    </Form>
  );
};
