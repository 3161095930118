import React, { useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { ItemRecord } from "../../types/common";
import { search } from "../../utils";

type DropdownPickerProps = {
  data: ItemRecord[];
  onSelect: (item: ItemRecord) => void;
  selected?: ItemRecord;
  placeholder: string;
  error?: string;
};

export const DropdownPicker: React.FC<DropdownPickerProps> = (props) => {
  const { data, selected, error } = props;
  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  function onDismiss() {
    setOpen(false);
  }
  function onSelect(item: ItemRecord) {
    onDismiss();
    props.onSelect(item);
  }

  return (
    <>
      <div
        contentEditable={false}
        onClick={() => setOpen(true)}
        className="form-control"
        placeholder={props.placeholder}
        // invalid={!!error}
      >
        {selected && selected.value !== ""
          ? selected?.label
          : props.placeholder}
      </div>
      <FormFeedback>{error}</FormFeedback>

      <BottomSheet
        open={open}
        className="bottom-sheet"
        defaultSnap={({ snapPoints, lastSnap }) =>
          lastSnap ?? Math.min(...snapPoints)
        }
        snapPoints={({ maxHeight }) => [maxHeight * 0.9, maxHeight * 0.9]}
        initialFocusRef={false}
        expandOnContentDrag={true}
        header={
          <FormGroup>
            <Label for="exampleSearch">Tìm kiếm</Label>
            <Input
              id="exampleSearch"
              name="search"
              placeholder="Gõ từ khóa tìm kiếm"
              type="text"
              autoFocus={false}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </FormGroup>
        }
        footer={
          <Button onClick={onDismiss} color="secondary" outline block>
            Đóng
          </Button>
        }
      >
        <div className="datalist">
          <ListGroup>
            {search(keyword, data).map((item) => (
              <ListGroupItem onClick={() => onSelect(item)} key={item.value}>
                <img
                  src={
                    item?.metadata?.logo ||
                    item?.metadata?.image ||
                    "https://via.placeholder.com/150"
                  }
                  alt={item.label}
                  width={32}
                  className="img-thumbnail me-3"
                />
                {item.label}
              </ListGroupItem>
            ))}
          </ListGroup>
        </div>
      </BottomSheet>
    </>
  );
};
