import ReactPlaceholder from "react-placeholder";

export const Loading = () => {
  return (
    <>
      {[1, 2, 3, 4].map((item) => (
        <ReactPlaceholder
          showLoadingAnimation
          type="text"
          className="mb-4"
          rows={4}
          ready={false}
        >
          <div />
        </ReactPlaceholder>
      ))}
    </>
  );
};
