import { createSlice } from '@reduxjs/toolkit'

export const valuationSlice = createSlice({
    name: 'valuation',
    initialState: {
        filter: {},
    },
    reducers: {
        setFilter: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.filter = {
                ...state.filter,
                ...action.payload
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { setFilter } = valuationSlice.actions

export default valuationSlice.reducer